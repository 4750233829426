import React from "react"
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CookieConsent from '../components/cookie-consent';
import GoogleTag from '../components/google-tag';
import FacebookPixel from '../components/facebook-pixel';
import MicrosoftClarity from "../components/microsoft-clarity";
import NavbarHelp from "../components/navbar-help";
import FooterHelp from "../components/footer-help";

export default function LayoutHelp({children, pageContext}) {
  const languagePath = pageContext?.language ? `/${pageContext.language}` : '';

  return (
      <>
        <div className="bg-slate-100">
          <NavbarHelp className="mx-auto max-w-7xl z-50" languagePath={languagePath}></NavbarHelp>
        </div>
        {children}
        <div className="bg-slate-100">
          <FooterHelp className="mx-auto max-w-7xl z-50" languagePath={languagePath}></FooterHelp>
        </div>
        <CookieConsent type="help"/>
        <GoogleTag/>
        <FacebookPixel/>
        <MicrosoftClarity/>
      </>
  )
}
