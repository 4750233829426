import React from 'react';
import Twitter from "../../assets/social-media/x.svg"
import Youtube from "../../assets/social-media/youtube.svg"
import Linkedin from "../../assets/social-media/linkedin.svg"
import Facebook from "../../assets/social-media/facebook.svg"
import {Trans} from "react-i18next";
import {Link} from "gatsby";


const ItemLink = ({path, children}: { path: string, children?: any }) => {
  return (
      <>
        {
          path.startsWith('https') ?
              <a href={path}
                 className="text-base text-slate-600 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-primary-200">
                {children}
              </a> :
              <Link to={path}
                    className="text-base text-slate-600 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-primary-200">
                {children}
              </Link>
        }
      </>
  );
}

const Links = ({languagePath, ...props}) => {
  return (
      <div {...props}>
        <div className="xl:grid xl:grid-cols-4 xl:gap-12">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-12 xl:col-span-4 justify-items-center">
            <div className="md:gap-12">
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider text-dark-blue-25">
                  <Trans>footer.company</Trans>
                </h3>
                <ul className="mt-4 space-y-3">
                  {/*<li>*/}
                  {/*  <ItemLink path={`${languagePath}/blog/about-us/`}>*/}
                  {/*    <Trans>footer.about</Trans>*/}
                  {/*  </ItemLink>*/}
                  {/*</li>*/}
                  <li>
                    <ItemLink path={`${languagePath}/testimonials`}>
                      <Trans>common.testimonials</Trans>
                    </ItemLink>
                  </li>
                  {/*<li>*/}
                  {/*  <ItemLink path='https://app.horarium.ai/topics/add'>*/}
                  {/*    <Trans>footer.updates</Trans>*/}
                  {/*  </ItemLink>*/}
                  {/*</li>*/}
                  <li>
                    <ItemLink path={`${process.env.GATSBY_APP_URL}/topics/add`}>
                      <Trans>common.contact-us</Trans>
                    </ItemLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:gap-12">
              <div className="mt-6 md:mt-0">
                <h3 className="text-sm font-semibold uppercase tracking-wider text-dark-blue-25">
                  <Trans>footer.legal</Trans>
                </h3>
                <ul className="mt-4 space-y-3">
                  <li>
                    <ItemLink path={`${process.env.GATSBY_APP_URL}/legal/terms`}>
                      <Trans>footer.terms-and-conditions</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${process.env.GATSBY_APP_URL}/legal/cookies`}>
                      <Trans>footer.privacy-policy</Trans>
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink path={`${process.env.GATSBY_APP_URL}/legal/privacy`}>
                      <Trans>footer.gdpr</Trans>
                    </ItemLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const Footer = ({languagePath, ...props}) => {

  return (
      <div {...props}>
        <div className="px-6 sm:px-0">
          <Links languagePath={languagePath} className="py-12 lg:py-16"></Links>
          <div className="h-px w-full bg-gradient-to-r from-transparent via-primary-600 to-transparent"></div>
        </div>
      </div>
  )
}

export default Footer;